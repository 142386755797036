@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #021112;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --df-messenger-chat-border: 0px;
  --df-messenger-input-background: #333;
  --df-messenger-message-border-radius: 100px;
  --df-messenger-input-box-focus-border: 3px solid rgba(255, 255, 255, 0.5);
  --df-messenger-input-box-border: 2px solid rgba(255, 255, 255, 0.2);
  --df-messenger-primary-color: rgba(167, 17, 145, 1);
}

df-messenger {
  z-index: 999;
  position: fixed;
  --df-messenger-font-color: #fff;
  --df-messenger-font-family: "Raleway";
  --df-messenger-font-size: 16px;
  --df-messenger-chat-background: rgba(0, 0, 0, 0.8);
  --df-messenger-message-user-background: rgba(167, 17, 145, 0.7);
  --df-messenger-message-bot-background: rgba(53, 192, 226, 0.7);
  --df-messenger-input-box-background: #171717;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100vw;
}

df-messenger.ai-support {
  font-family: "Ralway" !important;
  font-weight: 900 !important;
}
