.App {
  text-align: center;
  background: #021112;
  position: relative;
}
.App .color-logo {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  z-index: 1000;
  background-color: hwb(184 1% 93%);
  background: #121212 url("../src/assets/images/brain-ai-logo.png") no-repeat 0
    5px;
  margin-top: 0;
  padding: 10px;
  position: fixed;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.6);
  background-size: 205px;
}

.App .clear-chat {
  position: fixed;
  z-index: 2000;
  top: 30px;
  right: 30px;
}

.App-header .hack-logo {
  background: url("../src/assets/images/hack2024-logo-no-verbiage-bw.png")
    no-repeat center center;
  height: 100vh;
  width: 80%;
  background-size: 30%;
  position: fixed;
}

.App-header .robot-wrapper {
  position: fixed;
  bottom: 710px;
  right: 0;
}

.App-header .robot-wrapper .calculating {
  height: 478px;
  pointer-events: none;
  position: absolute;
  right: 158px;
  top: -109px;
  z-index: 20;
}

.App-header .robot-wrapper .robot {
  position: absolute;
  right: 98px;
  z-index: 50;
  height: 620px;
}

@media (prefers-reduced-motion: no-preference) {
  .calculating {
    animation: App-logo-spin infinite 15s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .App-header .robot-wrapper {
    right: -139px;
  }
  .App-header .robot-wrapper .calculating {
    height: 362px;
    pointer-events: none;
    position: absolute;
    right: 120px;
    top: -80px;
    z-index: 20;
  }

  .App-header .robot-wrapper .robot {
    position: absolute;
    right: 0;
    z-index: 50;
    height: 622px;
  }
}
